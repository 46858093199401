<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t('coaSetup.accountNumber')
                    }}</label>
                    <input
                        ref="account_number"
                        v-model="model.account_number"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('account_number') }"
                        :readonly="isUpdate"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('account_number')"
                    >
                        {{ errors.first('account_number') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t('coaSetup.nameKh')
                    }}</label>
                    <input
                        v-model="model.account_name_kh"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('account_name_kh') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('account_name_kh')"
                    >
                        {{ errors.first('account_name_kh') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t('coaSetup.nameEn')
                    }}</label>
                    <input
                        v-model="model.account_name_en"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('account_name_en') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('account_name_en')"
                    >
                        {{ errors.first('account_name_en') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t('coaSetup.coaCategory')
                    }}</label>
                    <select
                        v-model="model.acc_cat_id"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('acc_cat_id') }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none"
                        >
                            {{ $t('select') }}
                        </option>
                        <option
                            v-for="(cat_type, index) in coaCategories"
                            :key="index"
                            :value="cat_type.acc_cat_id"
                        >
                            {{ cat_type.acc_cat_name_en }}
                        </option>
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('acc_cat_id')"
                    >
                        {{ errors.first('acc_cat_id') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t('coaSetup.currency')
                    }}</label>
                    <select
                        v-model="model.currency_id"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('currency_id') }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none"
                        >
                            {{ $t('select') }}
                        </option>
                        <option
                            v-for="(c, index) in currencies"
                            :key="index"
                            :value="c.currency_id"
                        >
                            {{ c.currency_name_en }}
                        </option>
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('currency_id')"
                    >
                        {{ errors.first('currency_id') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t('coaSetup.vaultUser')
                    }}</label>
                    <select
                        v-model="model.vault_user_id"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('vault_user_id') }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none"
                        >
                            {{ $t('select') }}
                        </option>
                        <option
                            v-for="(u, index) in users"
                            :key="index"
                            :value="u.user_id"
                        >
                            {{
                                u.employee
                                    ? u.employee.employee_name_en +
                                      '|' +
                                      u.employee.employee_name_kh
                                    : ''
                            }}
                        </option>
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('vault_user_id')"
                    >
                        {{ errors.first('vault_user_id') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t('coaSetup.remarks')
                    }}</label>
                    <textarea
                        v-model="model.remarks"
                        rows="3"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('remarks') }"
                    ></textarea>
                    <div class="invalid-feedback" v-if="errors.has('remarks')">
                        {{ errors.first('remarks') }}
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <ts-checkbox v-model="model.is_active">{{
                    $t('coaSetup.isActive')
                }}</ts-checkbox>
                <ts-checkbox v-model="model.is_indirect_post">{{
                    $t('coaSetup.isIndirectPost')
                }}</ts-checkbox>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'coaSetupForm',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                account_number: null,
                account_name_kh: null,
                account_name_en: null,
                acc_cat_id: null,
                currency_id: null,
                remarks: null,
                is_active: true,
                vault_user_id: null,
                is_indirect_post: false
            }
        }
    },
    computed: {
        ...mapState('accounting/coaSetup', [
            'edit_data',
            'coaCategories',
            'currencies',
            'users'
        ]),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        ...mapActions('accounting/coaSetup', [
            'getCoaCategory',
            'getCurrency',
            'getUsers'
        ]),
        async fetchResource () {
            this.loading = true
            await this.getCoaCategory()
            await this.getCurrency()
            await this.getUsers()
            this.setEditData()
            this.loading = false
        },
        onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('accounting/coaSetup/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.clearInput()
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('accounting/coaSetup/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('accounting/coaSetup/update', {
                    id: this.edit_data.account_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            this.$nextTick(function () {
                this.$refs.account_number.focus()
            })
            if (!isEmpty(this.edit_data)) {
                this.model.account_number = this.edit_data.account_number
                this.model.account_name_kh = this.edit_data.account_name_kh
                this.model.account_name_en = this.edit_data.account_name_en
                this.model.acc_cat_id = this.edit_data.acc_cat_id
                this.model.currency_id = this.edit_data.currency_id
                this.model.remarks = this.edit_data.remarks
                this.model.is_active = this.edit_data.is_active
                this.model.vault_user_id = this.edit_data.vault_user_id
                this.model.is_indirect_post = this.edit_data.is_indirect_post
            }
        },
        clearInput () {
            this.model.account_number = null
            this.model.account_name_kh = null
            this.model.account_name_en = null
            this.model.acc_cat_id = null
            this.model.currency_id = null
            this.model.remarks = null
            this.model.is_active = true
            this.model.vault_user_id = null
            this.model.is_indirect_post = false
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'CoA SETUP',
                desc: not.text
            })
        }
    }
}
</script>
