<template>
    <div>
        <ts-page-title
            :title="$t('coaSetup.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('coaSetup.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t('addNew')
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('coaSetup.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="is_active">
                    <Icon
                        v-if="row.is_active"
                        type="ios-checkmark-circle-outline text-success"
                        size="20"
                    />
                    <Icon
                        v-else
                        type="ios-close-circle-outline text-danger"
                        size="20"
                    />
                </template>
                <template slot-scope="{ row }" slot="is_indirect_post">
                    <Icon
                        v-if="row.is_indirect_post"
                        type="ios-checkmark-circle-outline text-success"
                        size="20"
                    />
                    <Icon
                        v-else
                        type="ios-close-circle-outline text-danger"
                        size="20"
                    />
                </template>
                <template slot-scope="{ row }" slot="acc_cat_id">
                    {{
                        row.coa_category ? row.coa_category.acc_cat_name_en : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="acc_cat_type">
                    {{ row.coa_category ? row.coa_category.acc_cat_type : '' }}
                </template>
                <template slot-scope="{ row }" slot="currency_id">
                    {{ row.currency ? row.currency.currency_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="vault_user_id">
                    <p class="tw-whitespace-nowrap">
                        {{
                            row.user
                                ? row.user.employee
                                    ? row.user.employee.employee_name_en +
                                      '|' +
                                      row.user.employee.employee_name_kh
                                    : ''
                                : ''
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <i-switch
                        v-tooltip="$t('coaSetup.isActive')"
                        size="small"
                        v-model="row.is_active"
                        :loading="row._switching"
                        @on-change="onChangeIsActive(row)"
                        style="margin-right: 4px"
                    >
                    </i-switch>
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('coaSetup.pageTitle')"
                width="1200"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import FromAction from './form'

export default {
    name: 'coaSetupIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false,
            modal12: false,
            modal13: false,
            width: 'width: 300px'
        }
    },
    computed: {
        ...mapState('accounting/coaSetup', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.accounting.coaSetup.search
            },
            set (newValue) {
                this.$store.commit('accounting/coaSetup/SET_SEARCH', newValue)
                this.$store.commit('accounting/coaSetup/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    title: this.$t('coaSetup.accountNumber'),
                    key: 'account_number',
                    className: 'tw-whitespace-nowrap',
                    sortable: true,
                    maxWidth: 200
                },
                {
                    title: this.$t('coaSetup.nameEn'),
                    key: 'account_name_en',
                    className: 'tw-whitespace-nowrap',
                    sortable: true,
                    width: 350
                },
                {
                    title: this.$t('coaSetup.nameKh'),
                    key: 'account_name_kh',
                    className: 'tw-whitespace-nowrap',
                    sortable: true,
                    maxWidth: 350
                },
                {
                    title: this.$t('coaSetup.coaCategory'),
                    slot: 'acc_cat_id',
                    className: 'tw-whitespace-nowrap',
                    sortable: true,
                    maxWidth: 200
                },
                {
                    title: this.$t('coaSetup.accountCategoryType'),
                    slot: 'acc_cat_type',
                    sortable: true,
                    className: 'tw-whitespace-nowrap',
                    maxWidth: 200,
                    align: 'center'
                },
                {
                    title: this.$t('coaSetup.currency'),
                    slot: 'currency_id',
                    className: 'tw-whitespace-nowrap',
                    sortable: true,
                    maxWidth: 150
                },
                {
                    title: this.$t('coaSetup.isActive'),
                    slot: 'is_active',
                    align: 'center',
                    className: 'tw-whitespace-nowrap',
                    maxWidth: 120,
                    filters: [
                        {
                            label: 'Active',
                            value: true
                        },
                        {
                            label: 'Inactive',
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote (value) {
                        this.fetchData({
                            filter: {
                                is_active: value
                            }
                        })
                    }
                },
                {
                    title: this.$t('coaSetup.isIndirectPost'),
                    slot: 'is_indirect_post',
                    align: 'center',
                    className: 'tw-whitespace-nowrap',
                    filters: [
                        {
                            label: 'Is Indirect Post',
                            value: true
                        },
                        {
                            label: 'Is Not Indirect Post',
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote (value) {
                        this.fetchData({
                            filter: {
                                is_indirect_post: value
                            }
                        })
                    }
                },
                {
                    title: this.$t('coaSetup.vaultUser'),
                    slot: 'vault_user_id',
                    align: 'center',
                    minWidth: 65
                },
                {
                    title: this.$t('coaSetup.remarks'),
                    key: 'remarks',
                    className: 'tw-whitespace-nowrap'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 150
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('accounting/coaSetup/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        onEdit (record) {
            this.$store.commit('accounting/coaSetup/SET_EDIT_DATA', record)
            this.showForm = true
            this.$refs.form_action.fetchResource()
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit('accounting/coaSetup/SET_EDIT_DATA', {})
        },
        addNew () {
            this.$store.commit('accounting/coaSetup/SET_EDIT_DATA', {})
            this.showForm = true
            this.$refs.form_action.fetchResource()
            this.$refs.form_action.clearInput()
        },
        async onDelete (record) {
            record._deleting = true
            this.$store
                .dispatch('accounting/coaSetup/destroy', record.account_id)
                .then(response => {
                    this.fetchData()
                    record._deleting = false
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    record._deleting = false
                })
        },
        async onChangeIsActive (record) {
            record._switching = true
            this.$store
                .dispatch('accounting/coaSetup/changeIsActive', {
                    id: record.account_id,
                    data: { is_active: record.is_active }
                })
                .then(response => {
                    this.fetchData()
                    record._switching = false
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    record._switching = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'CoA SETUP',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('accounting/coaSetup/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
